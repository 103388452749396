<template>
  <div class="platform">
    <div>
      <img
        class="hidden-sm-and-down"
        style="width: 100%"
        src="../../assets/mock/platform/组 7472.png"
        alt=""
      />
      <img
        class="hidden-md-and-up"
        width="100%"
        src="../../assets/mock/platform/组 11465.jpg"
        alt=""
      />
    </div>
    <!-- frame -->
    <div class="warp frame">
      <h3 style="margin: 2.6042vw 0">整体框架介绍</h3>
      <el-row :gutter="10" class="frame_top">
        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
          <div class="box">
            <img src="../../assets/mock/platform/矩形 2580.png" alt="" />
            <h5>宇起APP</h5>
            <span></span>
            <p>APP一站式管理，多种开锁方式，灵活授权管理， 一流的使用体验。</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8"
          ><div class="box">
            <img src="../../assets/mock/platform/矩形 2580(1).png" alt="" />
            <h5>智能云平台</h5>
            <span></span>
            <p>为企业用户提供宇起智能设备管理及物品管理等增值服务</p> 
          </div></el-col
        >
        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8"
          ><div class="box">
            <img src="../../assets/mock/platform/矩形 2580(2).png" alt="" />
            <h5>智能硬件</h5>
            <span></span>
            <p>智能指纹密码器、智能指纹柜锁、智能箱包锁等系列产品</p>
          </div></el-col
        >
      </el-row>
    </div>
    <!-- introduce -->
    <div class="warp">
      <ul class="introduce">
        <li style="background-color: #3cb59e">
          <div>
            <img src="../../assets/mock/platform/组 371.png" alt="" />
            <h5>企业后台管理平台</h5>
            <p>
              宇起智能云平台上宇起信息化管理平台，为智能设备的数据和
              设备管理提供一站式服务，快捷远程管理和运维
            </p>
          </div>
        </li>
        <li>
          <img
            class="img"
            src="../../assets/mock/platform/组 1@2x.png"
            alt=""
          />
        </li>
        <li>
          <img
            class="img"
            src="../../assets/mock/platform/矩形 2591.png"
            alt=""
          />
        </li>
        <li style="background-color: #373744">
          <div>
            <img src="../../assets/mock/platform/组 1109.png" alt="" />
            <h5>宇起</h5>
            <p>
              在与用户不间断的交流反馈中，宇起智能软件开发提升了手机操作使用的体验，上千项体贴入微的功能改进，都能让它无比贴合您的使用习惯
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    //
  },
};
</script>

<style lang="scss" scoped>
.frame {
  overflow: hidden;
  text-align: center;
  .frame_top {
    div {
      margin-bottom: 0.625rem;
      overflow: hidden;
      h5 {
        margin-top: 0.625rem;
        font-size: 1rem;
      }
      p {
        padding: 0.625rem 2.6042vw;
        font-size: 0.875rem;
      }
      span {
        display: inline-block;
        height: 3px;
        width: 40px;
        background-color: #3cb59e;
      }
    }
    .box {
      -webkit-box-shadow: 0px 0px 6px rgba(163, 163, 163, 0.15);
      img {
        width: 100%;
        transition: all 0.2s;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
  }
}
.introduce {
  display: flex;
  flex-wrap: wrap;
  margin: 1.25rem 0;
  li {
    width: 50%;
    min-height: 14.8125rem;
    overflow: hidden;
    text-align: center;
    position: relative;
    .img {
      display: block;
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    div {
      width: 80%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 1.25rem;
      color: #fff;
      img {
        width: 3.3333vw;
        min-width: 1.8125rem;
      }
      h5 {
        margin: 0.625rem 0;
      }
      p {
        font-size: 0.875rem;
        text-align: left;
      }
    }
  }
}
</style>
